import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography } from '@material-ui/core';
import { ExpandMore, Search } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import InputMask from "react-input-mask";
import { status, units } from '../../../../enum';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '22px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const DEFAULT_QUERY_PARAMS = {
    studentCpf: '',
    studentName: '',
    unit: 0,
    startDate: moment().add(-15, 'days').format('YYYY-MM-DD'),
    endDate: moment().add(45, 'days').format('YYYY-MM-DD'),
    status: -1
};

const FilterAppointments = ({ search }) => {
    const classes = useStyles();
    const [filter, setFilter] = useState(DEFAULT_QUERY_PARAMS);
    const [fieldUnitIsEmpty, setFieldUnitIsEmpty] = useState(false);
    const [noContent, setNoContent] = useState(false);
    const [openFilterBox, setOpenFilterBox] = useState(true);

    const handlerChange = event => {
        const { name, value } = event.target;
        setFilter({
            ...filter,
            [name]: value
        });
    }

    const handlerChangeCustom = (key, value) => {
        setFilter({
            ...filter,
            [key]: value
        });

        if (key === 'unit' && fieldUnitIsEmpty) {
            setFieldUnitIsEmpty(false);
        }
    }

    const setHasContent = useCallback(hasContent => {
        setNoContent(!hasContent);
        setOpenFilterBox(!hasContent);
    }, []);

    const handlerSubmit = event => {
        event.preventDefault();

        if (filter['unit'] === '' || filter['unit'] === 0) {
            setFieldUnitIsEmpty(true);
            return;
        }
        setNoContent(false);
        search({ filter, setHasContent });
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handlerSubmit}>
                <Accordion expanded={openFilterBox} onChange={() => setOpenFilterBox(!openFilterBox)}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="filter-header"
                    >
                        <Typography className={classes.heading}>Filtros</Typography>
                        <Typography className={classes.secondaryHeading}>{noContent ? 'Nenhum resultado para a pesquisa.' : ''}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} className="p-2">
                            <Grid item md={4} xs={12}>
                                <Autocomplete
                                    name="unidade"
                                    options={units}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    multiple={false}
                                    onChange={(e, unidade) => { handlerChangeCustom('unit', unidade ? unidade.label : 0) }}
                                    renderInput={(params) =>
                                        <TextField
                                            error={fieldUnitIsEmpty}
                                            {...params}
                                            label="Unidade"
                                            fullWidth
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <KeyboardDatePicker
                                        required
                                        format="dd/MM/yyyy"
                                        invalidDateMessage="Data em formato inválido."
                                        id="startDate"
                                        label="Data Inicial"
                                        fullWidth
                                        value={filter.startDate}
                                        onChange={(value) => handlerChangeCustom('startDate', moment(value).format('YYYY-MM-DD'))}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        invalidDateMessage="Data em formato inválido."
                                        id="endDate"
                                        label="Data Final"
                                        fullWidth
                                        value={filter.endDate}
                                        onChange={(value) => handlerChangeCustom('endDate', moment(value).format('YYYY-MM-DD'))}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Autocomplete
                                    name="status"
                                    options={status}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    multiple={false}
                                    onChange={(e, status) => { handlerChangeCustom('status', status ? status.id : -1) }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Status"
                                            fullWidth
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    name="studentName"
                                    label="Nome Aluno"
                                    value={filter.studentName}
                                    onChange={handlerChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputMask mask="999.999.999-99" maskPlaceholder="" onChange={(e) => handlerChangeCustom('studentCpf', e.target.value.replace(/\D/g, ''))} value={filter.cpf} >
                                    <TextField id="telefone" name="cpf" fullWidth label="CPF Aluno" out />
                                </InputMask>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            type="submit"
                            size="large"
                            startIcon={<Search />}
                            color="primary">
                            Pesquisar
                        </Button>
                    </AccordionActions>
                </Accordion>
            </form>
        </div>
    );
}

export default FilterAppointments;