import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic';
import Appointments from '../Appointment';

export default function Index() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from='/' to='/agendamentos' />
                <Redirect exact from='/auth' to='/agendamentos' />
                <Route path='/agendamentos' component={Appointments} />
            </Switch>
        </Suspense>
    );
}
