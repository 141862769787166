export const SESC_ANAPOLIS = 1;
export const SESC_CIDADANIA = 2;
export const SESC_FAICALVILLE = 4;
export const SESC_UNIVERSITARIO = 5;
export const SESC_ITUMBIARA = 6;

export const units = [
    { id: SESC_ANAPOLIS, label: 'Sesc Anápolis' },
    { id: SESC_CIDADANIA, label: 'Sesc Cidadania' },
    { id: SESC_FAICALVILLE, label: 'Sesc FaiçalVille' },
    { id: SESC_UNIVERSITARIO, label: 'Sesc Universitário' },
    { id: SESC_ITUMBIARA, label: 'Sesc Itumbiara' }
];

export const getCodeByLabel = label => {
    const unit = units.find(u => u.label === label);

    if (!unit) {
        return SESC_CIDADANIA;
    }

    return unit.id;
}