import env from './../config/environments';
import api from './http/_api';

class AuthService {

    redirectToLogin() {
        this.clearSession();
        window.location = `${env.auth.baseUrlAuth}${env.auth.endPointLogin}?client_id=${env.auth.clientId}&response_type=${env.auth.responseType}&resource=${env.auth.resource}&redirect_uri=${env.auth.redirectUri}`;
        return;
    }

    logout() {
        this.clearSession();
        window.location = `${env.auth.baseUrlAuth}${env.auth.endPointLogout}?post_logout_redirect_uri=${env.auth.redirectUri}`;
        return;
    }

    async loginByAutorizationCode(code) {
        const response = await api.post('auth/office', { code });

        if (response.status === 200) {
            const { userInfo, ...token } = response.data
            this.initSession({
                ...token,
                user: userInfo
            });
        }

        return response;
    }

    async refreshToken() {
        const session = this.getSession();
        const response = await api.post('auth/refresh-token', { RefreshToken: session.refresh_token });

        if (response.status === 200) {
            this.initSession(response.data);
        }

        return response;
    }

    initSession(user) {
        localStorage.setItem('userSession', JSON.stringify(user));
    }

    isAuth() {
        return this.getSession() !== null;
    }

    getSession() {
        const session = localStorage.getItem('userSession');

        if (session) {
            return JSON.parse(session);
        }

        return null;
    }

    clearSession() {
        localStorage.clear();
    }

    getUserSession() {
        if (this.isAuth()) {
            const session = this.getSession();

            return session.user;
        }

        return {
            user: {
                name: '',
                email: ''
            },
        }
    }

}

export default new AuthService();