import moment from 'moment';
import { CANCELADO, CONFIRMADO } from '../../enum/appointment';
import { httpAppointmentService } from '../http/appointment';
import notificationService from '../notification';

const rescheduleService = {

    execute: async ({ appointment, newSchedule }) => {

        try {
            const commandCancel = {
                appointmentId: appointment.id,
                observation: `Cancelado mediante novo agendamento. (${moment(newSchedule.dateTime).format("DD/MM/YYYY HH:mm")})`,
                status: CANCELADO
            };

            const commandCreate = {
                scheduleId: newSchedule.id,
                message: appointment.message,
                studentName: appointment.studentName,
                studentCpf: appointment.studentCpf,
                responsibleCpf: appointment.responsibleCpf,
                unit: appointment.unit,
                class: appointment.class
            };

            const responseCancel = await httpAppointmentService.update(commandCancel);

            if (!!responseCancel === false || responseCancel.status !== 200) {
                throw new Error('Não foi possível cancelar o agendamento atual. Atualize a página e tente novamente.');
            }

            const responseCreate = await httpAppointmentService.create(commandCreate);

            if (!!responseCreate === false || responseCreate.status !== 200) {
                throw new Error('Não foi possível cadastrar um novo agendamento. Atualize a página e tente novamente.');
            }

            const commandConfirm = {
                appointmentId: responseCreate.data.id,
                observation: newSchedule.observation,
                status: CONFIRMADO
            }

            const responseConfirm = await httpAppointmentService.update(commandConfirm);

            if (!!responseConfirm === false || responseConfirm.status !== 200) {
                throw new Error('Não foi possível confirmar o novo agendamento. Atualize a página e tente novamente.');
            }

            notificationService
                .send({
                    cpf: appointment.studentCpf,
                    title: 'Agendamento remarcado.',
                    message: `Seu agendamento para o dia ${moment(appointment.schedule.date).format("DD/MM/YYYY")} `
                        + `às ${moment(appointment.schedule.date).format("HH:mm")} foi remarcado para o dia `
                        + `${moment(newSchedule.dateTime).format("DD/MM/YYYY")} às ${moment(newSchedule.dateTime).format("HH:mm")}, acesse o App para mais informações.`
                });

            return {
                succeeded: true,
                message: 'Agendamento alterado com sucesso!'
            }

        } catch (error) {

            return {
                succeeded: false,
                message: error.message
            }
        }
    }
}

export {
    rescheduleService
}