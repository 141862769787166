
import { toast } from 'react-toastify';
import { all, takeLatest } from 'redux-saga/effects';

//import api from '../../services/http/_api';

export function* signIn({ payload }) {
    try {

    } catch (error) {
        toast.error('Falha na autenticação, verifique seus dados de acesso.');
    }
}

export function* signOut() {
    try {

    } catch (error) {
        toast.error('Falha no logout.');
    }
}

export default all([
    takeLatest('@auth/SIGN_IN', signIn),
    takeLatest('@auth/SIGN_OUT', signOut),
]);