import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, CircularProgress } from '@material-ui/core';
import { httpScheduleService } from '../../../../services/http/schedule';

import Calendar from './date';
import Time from './hour';

const DateTime = ({ unit, setNewSchedule }) => {
    const [avaliableDates, setAvaliableDates] = useState([]);
    const [avaliableHours, setAvaliableHours] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [firstDateAvaliable, setFirstDateAvaliable] = useState(null);
    const [lastDateAvaliable, setLastDateAvaliable] = useState(null);

    useEffect(() => {
        httpScheduleService
            .avaliableDates({ unitId: unit })
            .then(({ data }) => {
                setAvaliableDates(data);
                const firstDateTime = new Date(data[0].dateTime);
                const lastDateTime = new Date(data[data.length - 1].dateTime);
                setFirstDateAvaliable(firstDateTime);
                setLastDateAvaliable(lastDateTime);
                setSelectedDay(firstDateTime);
            });


        /*         dates()
                    .get(unit)
                    .then(({ data }) => {
                        setAvaliableDates(data);
                        const firstDateTime = new Date(data[0].dateTime);
                        const lastDateTime = new Date(data[data.length - 1].dateTime);
                        setFirstDateAvaliable(firstDateTime);
                        setLastDateAvaliable(lastDateTime);
                        setSelectedDay(firstDateTime);
                    }); */
    }, [unit]);

    useEffect(() => {
        if (selectedDay != null) {
            httpScheduleService
                .avaliableHours({ unit, date: moment(selectedDay).format('YYYY-MM-DD') })
                .then(({ data }) => {
                    setAvaliableHours(data);
                });
            /* 
            hours()
                .get({ unit, dateTime: moment(selectedDay).format('YYYY-MM-DD') })
                .then(({ data }) => {
                    setAvaliableHours(data);
                }); */
        }
    }, [unit, selectedDay]);

    useEffect(() => {
        if (selectedSchedule !== null) {
            setNewSchedule(selectedSchedule);
        }
    }, [selectedSchedule]);

    return (
        <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{ marginBottom: '20px' }}>
            {
                avaliableHours.length === 0 ? (
                    <CircularProgress color="primary" />
                ) : (
                        <>
                            <Calendar handleDay={{ selectedDay, setSelectedDay, avaliableDates, firstDateAvaliable, lastDateAvaliable }} />
                            <Time handleHour={{ setSelectedSchedule, avaliableHours }} />
                        </>
                    )
            }
        </Grid>
    )
}

export default DateTime;