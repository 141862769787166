import styled from 'styled-components';

export const Container = styled.div`
  width: 260px;
  margin-left: 20px;
  margin-right: 20px;

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #EBEBEC;
    border-radius: 50%;
    //color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: '#312F38';
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
    //background: #312F38 !important;
  }

  .DayPicker-Day--selected {
    background: #6D85FF !important;
    border-radius: 50%;
    color: #232129 !important;
  }
`;
