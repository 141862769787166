import api from './_api';

const httpAppointmentService = {

    search: async params => {
        if (!params) {
            throw new Error('Informe os parâmetros para pesquisa.');
        }

        let uri = `/appointment/all?Unit=${params.unit}&StartDate=${params.startDate}&EndDate=${params.endDate}`;

        if (params.status >= 0) {
            uri += `&Status=${params.status}`
        }

        if (params.studentCpf && !!params.studentCpf.trim()) {
            uri += `&StudentCpf=${params.studentCpf}`
        }

        if (params.studentName && !!params.studentName.trim()) {
            uri += `&StudentName=${params.studentName}`
        }

        return await api.get(uri);
    },

    create: async command => {
        if (!command) {
            throw new Error('Requisição inválida.');
        }

        return await api.post('/appointment', command);
    },

    update: async command => {
        if (!command) {
            throw new Error('Requisição inválida.');
        }

        return await api.put('/appointment', command);
    }
}

export {
    httpAppointmentService
}