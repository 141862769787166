import React, { useCallback, useMemo } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Container } from './styles';

const Calendar = ({ handleDay }) => {
    const {
        selectedDay,
        setSelectedDay,
        avaliableDates,
        firstDateAvaliable,
        lastDateAvaliable
    } = handleDay;

    const disabledDays = useMemo(() => {
        const dates = [];
        const year = firstDateAvaliable.getFullYear();
        const firstMonth = firstDateAvaliable.getMonth();
        const lastMonth = lastDateAvaliable.getMonth();

        const mapDayMonthAvaliable = new Map();
        avaliableDates.map(({ dateTime }) => {
            const date = new Date(dateTime);
            const dayMonth = `${date.getDate()}-${date.getMonth()}`;
            mapDayMonthAvaliable.set(dayMonth, date);
        });

        for (let m = firstMonth; m <= lastMonth; m++) {
            let firstDayAvaliable = firstDateAvaliable.getDate();
            let lastDayMonth = new Date(year, m + 1, 0).getDate();

            for (let d = firstDayAvaliable; d <= lastDayMonth; d++) {
                let dayMonth = `${d}-${m}`;

                if (mapDayMonthAvaliable.has(dayMonth)) {
                    continue;
                }
                dates.push(new Date(year, m, d));
            }
        }
        return dates;
    }, [avaliableDates]);

    const handleDateChange = useCallback((day, modifiers) => {
        if (modifiers.available && !modifiers.disabled) setSelectedDay(day);
    }, []);

    return (
        <Container>
            <DayPicker
                weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                fromMonth={firstDateAvaliable}
                toMonth={lastDateAvaliable}
                month={firstDateAvaliable}
                disabledDays={[{ before: firstDateAvaliable }, ...disabledDays]}
                modifiers={{
                    available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                }}
                onMonthChange={() => { }}
                selectedDays={selectedDay}
                onDayClick={handleDateChange}
                months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                ]}
            />
        </Container>
    );
}

export default Calendar;