import api from './_api';

const httpScheduleService = {

    avaliableDates: async ({ unitId }) => {
        if (!unitId) {
            throw new Error('Informe o código da unidade.');
        }

        return await api.get(`schedule/dates/${unitId}`);
    },

    avaliableHours: async ({ unit, date }) => {
        if (!unit || !date) {
            throw new Error('Informe o código da unidade e a data.');
        }

        return await api.get(`schedule/hours/${unit}/${date}`);
    }
}

export {
    httpScheduleService
}