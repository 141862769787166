import React from "react";
import env from './../../config/environments';
import api from './../../services/http/_api';
import AuthService from './../../services/auth.service';
import { toast } from "react-toastify";

const Interceptor = props => {
    const { setLoading } = props;

    const notAuthUrl = url => {
        let notAuth = false;
        for (let endpoint of env.auth.routerNotAuth) {
            if (
                url.toLowerCase().startsWith(endpoint)
            ) {
                notAuth = true;
            }
        }

        return notAuth;

    };

    const loadingOpen = config => {
        if (config && (config.spinner === undefined || config.spinner === true)) {
            setLoading(true);
            return;
        }
    }

    const loadingClose = () => {
        setLoading(false);
    }

    const toastError = (data, status) => {
        if (status === 400) {
            if (data.content.length > 0) {
                data.content.forEach(e => toast.error(e.message.join(" ")));
            }
            return;
        }


        toast.error(
            "Ocorreu um erro não esperado ao tentar processar sua requisição. Por favor, atualize a página e tente novamente."
        );
    }

    api.interceptors.request.use(async function (config) {

        if (!notAuthUrl(config.url)) {
            let auth = AuthService.getSession();
            if (auth && auth.accessToken) {
                config.headers.Authorization = `Bearer ${auth.accessToken}`;
            }
        }

        loadingOpen(config);

        return config;
    });

    api.interceptors.response.use(
        async function (response) {
            loadingClose()
            return response;
        },
        async function (error) {
            loadingClose();

            if (error.message === 'Network Error' && !error.response) {
                toastError();
                return;
            }

            const {
                config,
                response: { status, data }
            } = error;

            const originalRequest = config;

            if (status === 401 && !notAuthUrl(config.url)) {
                let response = await AuthService.refreshToken();

                if (response.status === 200) {
                    const retryOriginalRequest = new Promise(resolve => {
                        resolve(api(originalRequest));
                    });

                    return retryOriginalRequest;
                } else {
                    AuthService.redirectToLogin();
                }

            }

            if ((status === 400 || status > 401) && (config.toast === undefined || config.toast === true)) {
                toastError(data, status);
            }

            return Promise.reject(error);
        }
    );


    return (
        <></>
    )

}

export default Interceptor;