import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from "../../../../_metronic";
import AuthService from '../../../services/auth.service';
import { signOut } from '../../../store/auth/actions';

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(signOut());
        AuthService.logout();
    }, []);

    return (
        <LayoutSplashScreen />
    )
}

export default Logout;
