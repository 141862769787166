import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import List from './components/list';
import Filter from './components/filter';
import ModalActions from './components/modal';
import { httpAppointmentService } from '../../services/http/appointment';

const Appointments = () => {
    const modalActionsRef = useRef(null);
    const filterSearch = useRef({ value: null });
    const [appointments, setAppointments] = useState([]);

    const search = useCallback(async ({ filter, setHasContent = null }) => {
        try {
            filterSearch.current.value = filter;
            const response = await httpAppointmentService.search(filter); //await appointment().get(filter);

            if (response && response.status == 200) {
                if (typeof setHasContent === 'function') {
                    setHasContent(response.data.length > 0);
                }
                setAppointments(response.data);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }, []);

    const research = useCallback(() => {
        const filter = filterSearch.current.value;
        search({ filter });
    }, []);

    const handleModalActions = agendamento => {
        modalActionsRef.current.active(agendamento);
    }

    return (
        <>
            <Filter
                search={search}
            />

            <List
                data={appointments}
                handleModalActions={handleModalActions}
            />

            <ModalActions
                ref={modalActionsRef}
                updateList={research}
            />
        </>
    )
}

export default Appointments;