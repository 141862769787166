import moment from 'moment';
import { CANCELADO, CONFIRMADO } from '../../enum/appointment';
import { httpAppointmentService } from '../http/appointment';
import notificationService from '../notification';

const updateAppointmentService = {

	toCanceled: async ({ appointment }) => {

		try {
			const command = {
				appointmentId: appointment.id,
				observation: appointment.observation,
				status: CANCELADO
			};

			const response = await httpAppointmentService.update(command);

			if (!!response === false || response.status !== 200) {
				throw new Error('Não foi possível cancelar o agendamento. Atualize a página e tente novamente.');
			}

			notificationService
				.send({
					cpf: appointment.studentCpf,
					title: 'Agendamento cancelado.',
					message: `Seu agendamento para o dia ${moment(appointment.schedule.date).format("DD/MM/YYYY")} `
						+ `às ${moment(appointment.schedule.date).format("HH:mm")} foi cancelado, acesse o App para mais informações.`
				});

			return {
				succeeded: true,
				message: 'Agendamento cancelado com sucesso!'
			}

		} catch (error) {

			return {
				succeeded: false,
				message: error.message
			}
		}
	},

	toConfirmed: async ({ appointment }) => {

		try {
			const command = {
				appointmentId: appointment.id,
				status: CONFIRMADO
			};

			const response = await httpAppointmentService.update(command);

			if (!!response === false || response.status !== 200) {
				throw new Error('Não foi possível confirmar o agendamento. Atualize a página e tente novamente.');
			}

			notificationService
				.send({
					cpf: appointment.studentCpf,
					title: 'Agendamento confirmado.',
					message: `Seu agendamento para o dia ${moment(appointment.schedule.date).format("DD/MM/YYYY")} `
						+ `às ${moment(appointment.schedule.date).format("HH:mm")} foi confirmado, acesse o App para mais informações.`
				});

			return {
				succeeded: true,
				message: 'Agendamento confirmado com sucesso!'
			}

		} catch (error) {

			return {
				succeeded: false,
				message: error.message
			}
		}
	}
}

export {
	updateAppointmentService
}