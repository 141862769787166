export default {
  header: {
    self: {},
    items: [
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Agendamentos",
        root: true,
        icon: "fa fa-calendar-alt",
        page: "agendamentos",
        translate: "MENU.LISTA_AGENDAMENTOS",
        bullet: "dot"
      }
    ]
  }
};
