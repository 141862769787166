import { toast } from 'react-toastify';
import api from './http/_api';

const notificationService = {

    send: async ({ cpf, title, message }) => {
        try {
            if (!cpf || !title || !message) {
                throw new Error();
            }

            const response = await api.get(`aluno/dados-aluno?cpf=${cpf}`);

            if (!!response === false || response.status !== 200) {
                throw new Error();
            }

            const { codigoAluno } = response.data;

            const command = {
                title,
                message,
                codigoAluno
            }

            await api.post('notification/create', command);

        } catch (error) {
            toast.warning('Não foi possível notificar o solicitante.');
        }
    }
};

export default notificationService;