import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
        justifyContent: 'center',
        marginRight: 20
    },
    tab: {
        minWidth: 85,
        minHeight: 36,
        fontSize: '1.1rem'
    }
}));

const HoursList = ({ handleHour }) => {
    const classes = useStyles();
    const { avaliableHours, setSelectedSchedule } = handleHour;
    const [value, setValue] = useState(0);

    const handleClick = shedule => {
        setValue(shedule.id);
        setSelectedSchedule(shedule);
    }

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                indicatorColor="primary"
                textColor="primary"
                //onChange={handleChange}
                aria-label="hours list"
            >
                {
                    avaliableHours.map((item, index) => (
                        <Tab
                            key={index}
                            label={item.hour}
                            value={item.id}
                            id={`vertical-tab-${index}`}
                            aria-controls={`vertical-tabpanel-${index}`}
                            className={classes.tab}
                            onClick={() => handleClick(item)}
                        />
                    ))
                }
            </Tabs>
        </div >
    );
}

export default HoursList;