export const cpf = value => {
    const cpfClean = value.replace(/[^\d]/g, "");

    const clean = () => {
        return cpfClean
    }

    const format = () => {
        return cpfClean.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }
    return {
        clean,
        format
    };
}