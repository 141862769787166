import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default function (reducers) {
    return persistReducer(
        {
            key: 'sesc-edu',
            storage,
            whitelist: ['auth']
        },
        reducers
    );
}