import React, { useEffect, Fragment } from 'react';
import AuthService from '../../../services/auth.service';

import { useDispatch } from 'react-redux';
import { signIn } from '../../../store/auth/actions';

const Authorization = ({ location }) => {
    const dispatch = useDispatch();

    const { search } = location;
    const paramsBusca = new URLSearchParams(search);
    const code = paramsBusca.get('code');

    useEffect(() => {
        loginByAutorizationCode();
    }, [])

    const loginByAutorizationCode = async () => {
        if (code != null) {
            const response = await AuthService.loginByAutorizationCode(code);

            if (response.status === 200) {
                const { accessToken, userInfo } = response.data;
                dispatch(signIn({ authToken: accessToken, user: userInfo }));
            }
        } else {
            AuthService.redirectToLogin();
        }
    };

    return <Fragment />;
}

export default Authorization;