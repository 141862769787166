const environment = {
    api: {
        //baseUrl: 'https://localhost:44367/api/'
        baseUrl: 'https://appapisescgoon.sescgo.com.br/api/v1/'
    },
    auth: {
        //baseUrlAuth : 'http://localhost:55571/',
        baseUrlAuth: 'https://login.microsoftonline.com/',
        endPointLogin: 'common/oauth2/authorize',
        endPointLogout: 'common/oauth2/v2.0/logout',
        clientId: 'b6c0e16a-f1a1-4646-bd9a-297c1829857f',
        resource: 'b6c0e16a-f1a1-4646-bd9a-297c1829857f',
        responseType: 'code',
	//redirectUri: 'http://localhost:3000/auth',
	redirectUri: 'https://backofficesescedu.sescgo.com.br/auth',
        pathName: '/auth',
        routerNotAuth: [
            '/v1/auth',
            '/v1/auth/refresh-token'
        ]
    },
    toast: {
        timer: 3000,
    },
}

export default environment;
