import React from 'react';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import { getLabelStatus } from '../../../../enum/appointment';
import { cpf } from '../../../../utils';

const ListAppointments = ({ data, handleModalActions }) => {

    return (
        <>
            {data.length > 0 &&
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ fontSize: 16 }}>
                                <TableCell align="center">CPF</TableCell>
                                <TableCell align="center">ALUNO</TableCell>
                                <TableCell align="center">RESPONSÁVEL</TableCell>
                                <TableCell align="center">DATA</TableCell>
                                <TableCell align="center">HORA</TableCell>
                                <TableCell align="center">UNIDADE</TableCell>
                                <TableCell align="center">STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((agendamento, key) => (
                                <TableRow key={key} style={{ cursor: 'pointer' }} hover onClick={() => handleModalActions(agendamento)} align="center">
                                    <TableCell align="center">{cpf(agendamento.studentCpf).format()}</TableCell>
                                    <TableCell align="center">{agendamento.studentName}</TableCell>
                                    <TableCell align="center">{agendamento.responsibleName}</TableCell>
                                    <TableCell align="center">{moment(agendamento.schedule.date).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell align="center">{moment(agendamento.schedule.date).format("HH:mm")}</TableCell>
                                    <TableCell align="center">{agendamento.unit}</TableCell>
                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>{getLabelStatus(agendamento.status)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default ListAppointments;