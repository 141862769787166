const initialState = {
    user: {
        name: '',
        email: ''
    },
    authToken: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case '@auth/SIGN_IN': {
            const { authToken, user } = action.payload;
            return { authToken, user };
        }
        case '@auth/SIGN_OUT': {
            return { ...initialState };
        }
        default:
            return state;
    }
}