export function signIn({ authToken, user }) {
    return {
        type: '@auth/SIGN_IN',
        payload: { authToken, user }
    }
}

export function signOut() {
    return {
        type: '@auth/SIGN_OUT'
    }
}