export const PENDENTE = 0;
export const CONFIRMADO = 1;
export const CANCELADO = 2;

export const status = [
    { id: PENDENTE, label: 'Pendente' },
    { id: CONFIRMADO, label: 'Confirmado' },
    { id: CANCELADO, label: 'Cancelado' }
];

export const getLabelStatus = id => {
    const statusResult = status.find(s => s.id === id);

    if (statusResult) {
        return statusResult.label;
    }

    return '';
}