import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography, IconButton, Collapse, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EventAvailable, Event, Close, EventBusy, Undo } from '@material-ui/icons';
import { getLabelStatus } from '../../../../enum/appointment';
import { getCodeByLabel } from '../../../../enum/units';
import { cpf } from '../../../../utils';
import DateTimeChange from '../reschedule';
import { updateAppointmentService } from '../../../../services/appointment/update';
import { rescheduleService } from '../../../../services/appointment/reschedule';

const useStyles = makeStyles(theme => ({
    marginBt: {
        marginBottom: 22,
    },
    button: {
        margin: theme.spacing(1),
    },
    icon: {
        marginRight: 5
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const ModalActions = ({ updateList }, ref) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const [inputIsEmpty, setInputIsEmpty] = useState(false);
    const [open, setOpen] = useState(false);
    const [appointment, setAppointment] = useState(null);
    const [changeEnabled, setChangeEnabled] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [newSchedule, setNewSchedule] = useState(null);

    const close = useCallback(() => {
        setAppointment(null);
        setInputIsEmpty(false);
        setOpenCalendar(false);
        setChangeEnabled(false);
        setNewSchedule(null)
        setOpen(false);
    }, []);

    const handleResult = useCallback(({ succeeded, message }) => {
        if (!succeeded) {
            toast.error(message);
            return;
        }

        toast.success(message);
        updateList();
        close();
    }, [updateList, close]);

    const handleActionUndo = useCallback(() => {
        if (!changeEnabled) {
            close();
            return;
        }

        setInputIsEmpty(false);
        setNewSchedule(null);
        setOpenCalendar(false);
        setChangeEnabled(false);
    }, [changeEnabled, close]);

    const handleActionConfirm = useCallback(async () => {
        const { succeeded, message } = await updateAppointmentService.toConfirmed({ appointment });

        handleResult({ succeeded, message });
    }, [appointment, handleResult]);

    const handleActionCancel = useCallback(async () => {
        if (!changeEnabled) {
            setChangeEnabled(true);
            return;
        }

        if (!inputRef.current.value) {
            setInputIsEmpty(true);
            return;
        }

        const { succeeded, message } = await updateAppointmentService.toCanceled({
            appointment: {
                ...appointment,
                observation: inputRef.current.value
            }
        });

        handleResult({ succeeded, message });
    }, [changeEnabled, appointment, handleResult]);

    const handleActionReschedule = useCallback(async () => {
        if (!changeEnabled) {
            setChangeEnabled(true);
            setOpenCalendar(true);
            return;
        }

        newSchedule.observation = inputRef.current.value || '';
        const { succeeded, message } = await rescheduleService.execute({ appointment, newSchedule });

        handleResult({ succeeded, message });
    }, [changeEnabled, appointment, newSchedule, handleResult]);

    useImperativeHandle(ref, () => ({
        active: data => {
            setAppointment(data);
            setOpen(true);
        }
    }));

    if (appointment === null) return;

    return (
        <Dialog
            open={open}
            aria-labelledby="info of schedule"
            fullWidth
            maxWidth="sm"
            scroll="body"
        >
            <DialogTitle disableTypography>
                <Typography variant="h6">Solicitação de Agendamento</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => close()}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider style={{ marginBottom: '18px' }} />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Data: </span> {`${moment(appointment.schedule.date).format("DD/MM/YYYY")} às ${moment(appointment.schedule.date).format("HH:mm")}`}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Status: </span> {getLabelStatus(appointment.status)}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Aluno: </span> {appointment.studentName}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>CPF: </span> {cpf(appointment.studentCpf).format()}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Responsável: </span> {appointment.responsibleName}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Email: </span> {appointment.emailAddress}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Unidade: </span> {appointment.unit}
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classes.bold}>Turma: </span> {appointment.class}
                    </Grid>
                    <Grid item xs={12}>
                        <span className={classes.bold}>Telefone: </span> {appointment.phoneNumber}
                    </Grid>
                    <Grid item xs={12}>
                        <span className={classes.bold}>Mensagem: </span> {appointment.message}
                    </Grid>
                    {
                        appointment.observation && (
                            <Grid item xs={12}>
                                <span className={classes.bold}>Observação: </span> {appointment.observation}
                            </Grid>
                        )
                    }
                </Grid>

                <Divider style={{ marginTop: '22px', marginBottom: '26px' }} />

                <Collapse in={changeEnabled}>
                    {
                        openCalendar && (
                            <DateTimeChange unit={getCodeByLabel(appointment.unit)} setNewSchedule={setNewSchedule} />
                        )
                    }
                    <TextField
                        helperText={inputIsEmpty ? 'Este campo deve ser preenchido!' : ''}
                        error={inputIsEmpty}
                        inputRef={inputRef}
                        id="field-observation"
                        label="Observação"
                        variant="outlined"
                        style={{ width: '100%', marginBottom: '26px' }}
                    />
                </Collapse>

                <Grid container spacing={2} justify="center" className={classes.marginBt}>
                    <Button variant="text" className={classes.button} onClick={() => handleActionUndo()}>
                        <Undo className={classes.icon} />
                        Voltar
                    </Button>
                    {
                        (!changeEnabled || (changeEnabled && !openCalendar)) && (
                            <Button disabled={appointment.status !== 0} variant="text" color={changeEnabled ? 'primary' : 'default'} className={classes.button} onClick={() => handleActionCancel()}>
                                <EventBusy className={classes.icon} />
                                Cancelar
                            </Button>
                        )
                    }
                    {
                        (!changeEnabled || (changeEnabled && openCalendar)) && (
                            <Button disabled={appointment.status !== 0} variant="text" color={changeEnabled ? 'primary' : 'default'} className={classes.button} onClick={() => handleActionReschedule()}>
                                <Event className={classes.icon} />
                                Alterar
                            </Button>
                        )
                    }
                    {
                        !changeEnabled && (
                            <Button disabled={appointment.status !== 0} variant="text" color="primary" className={classes.button} onClick={() => handleActionConfirm()}>
                                <EventAvailable className={classes.icon} />
                                Confirmar
                            </Button>
                        )
                    }
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default forwardRef(ModalActions);
