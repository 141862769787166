import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import Home from "../pages/Home";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import Interceptor from "./../components/interceptor";
import { ToastContainer } from 'react-toastify';
import env from './../config/environments';

import SignIn from "../components/Auth/SignIn";
import SignOut from '../components/Auth/SignOut';

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const [loading, setLoading] = useState(false);
  routerHelpers.saveLastLocation(lastLocation);
  let { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: !!auth.authToken,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Interceptor setLoading={setLoading} />
      <ToastContainer autoClose={env.toast.timer} />
      <Switch>
        {!isAuthorized ? (
          <>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/auth" component={SignIn} />
          </>
        ) : (
            <>
              <Route path="/logout" component={SignOut} />
              <Layout loading={loading}>
                <Home userLastLocation={userLastLocation} />
              </Layout>
            </>
          )}
      </Switch>
    </LayoutContextProvider>
  );
});
